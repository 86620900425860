import { View } from 'backbone';
import Template from '../templates/main.html';

const Main = View.extend({
   el: '#main',
   events: {
     'click .forward-link': 'toGallery'
   },
   initialize() {
      this.render();
   },
   toGallery(e) {
     e.preventDefault();
     app.navigate('gallery', { trigger: true });
   },
   render() {
      this.$el.html(Template);
   }
});

export default Main;
