import { View } from 'backbone';
import Template from '../templates/header.html';

const Header = View.extend({
   el: '#header',
   events: {
     'click #goBack': 'goBack',
     'click #goBack': 'goBack',
     'click #goGallery': 'goGallery',
     'click #openModal': 'openModal'
   },
   initialize(opts) {
      this.render();
      this.spotlight = opts.spotlight;
      this.$galleryButton = $('#goGallery');
      this.$modalButton = $('#openModal');
      this.$sendButton = $('#send');
      this.listenTo(window.app, 'route:gallery', this.setButtons);
      this.listenTo(window.app, 'route', this.setButtons);
      $('.modal').modal();
      $('#send').click(this.send);
   },
   setButtons(e) {
     console.log(e);
     if (this.spotlight[e]) {
       this.$galleryButton.show();
       this.$modalButton.show();
     } else if (e === 'gallery') {
       this.$modalButton.show();
     } else {
       this.resetButtons();
     }
   },
   resetButtons() {
     this.$galleryButton.hide();
     this.$modalButton.hide();
   },
   goBack(e) {
     e.preventDefault();
     app.navigate('', { trigger: true });
     this.resetButtons();
   },
   goGallery(e) {
     e.preventDefault();
     app.navigate('gallery', { trigger: true })
   },
   openModal(e) {
     e.preventDefault();
     $('#form').show();
     $('#progress').hide();
     $('#thankYou').hide();
     $('#error').hide();
     $('.modal').modal('open');
   },
   send(e) {
     e.preventDefault();
     const name = $('input[name="name"]').val();
     const email = $('input[name="email"]').val();
     const url = Backbone.history.location.href;

     console.log(`email: ${email}`);
     console.log(`name: ${name}`);
     console.log(`url: ${url}`);

     $('#form').hide();
     $('#progress').show();

     const request = $.ajax({
       url: 'https://goout-alchemy3.herokuapp.com/api/mailer',
       data: { name, email, url },
       type: 'post'
     });

     request.then((response) => {
       console.log(response);
       $('#progress').hide();
       $('#thankYou').show();
     }).catch((error) => {
       $('#progress').hide();
       $('#errMsg').html(error.statusText);
       console.log(error);
       $('#error').show();
     });
   },
   render() {
      this.$el.html(Template);
      return this;
   }
});

export default Header;
