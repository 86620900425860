import { View } from 'backbone';
import Template from '../templates/notfound.html';

const NotFound = View.extend({
   el: '#main',
   initialize() {
      this.render();
   },
   render(params) {
      this.$el.html(Template);
   }
});

export default NotFound;
