import { View } from 'backbone';
import Template from '../templates/footer.html';

const Footer = View.extend({
   // el - stands for element. Every view has an element associated with HTML content, will be rendered.
   el: '#footer',
   events: {
     'click .go-back': 'goBack'
   },
   // It's the first function called when this view is instantiated.
   initialize() {
      this.render();
   },
   render() {
      this.$el.html(Template);
      return this;
   },
   goBack(e) {
     e.preventDefault();
     app.navigate('', { trigger: true });
   },
});

export default Footer;
