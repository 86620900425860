import { View } from 'backbone';
import Template from '../templates/gallery.html';

const Gallery = View.extend({
   el: '#main',
   events: {
     'click .carousel-item': 'goToSpotlight',
     'click .btn-next': 'nextSlide',
     'click .btn-prev': 'prevSlide'
   },
   render() {
      this.$el.html(Template);
      return Promise.resolve(this);
   },
   postinitialize() {
     this.carouselInitialized = true;
   },
   nextSlide(e) {
      e.preventDefault();
      this.$mainGallery.carousel('next');
   },
   prevSlide(e) {
     e.preventDefault();
     this.$mainGallery.carousel('prev');
   },
   initialize() {
      this.render();
      this.$mainGallery = $('.main-carousel');

      this.$mainSlides = this.$mainGallery.find('> a');
      this.$descrGallery = $('#addCarousel');

      if (!this.carouselInitialized) {
        this.$descrGallery.carousel({ numVisible: 1 });

        const $descrGallery = this.$descrGallery;
        const $mainSlides = this.$mainSlides;

        this.$mainGallery.carousel({
         dist: -20,
         padding: 20,
         shift: 20,
         onCycleTo(slide) {
           $descrGallery.carousel('set', $mainSlides.index(slide) || 0);
         }
        });

        this.postinitialize.bind(this);
      }
   },
   goToSpotlight(e) {
     e.preventDefault();
     const href = $(e.currentTarget).data('href');
     app.navigate(href, { trigger: true });
   }
});

export default Gallery;
