import { View } from 'backbone';
import template from 'lodash.template';
import Template from '../templates/spotlight.html';

const Spotlight = View.extend({
   el: '#main',
   template: template(Template),
   initialize(params) {
      this.render(params);
      initSlickCarousel();
      initTabs();
   },
   render(params) {
     this.$el.html(this.template(params));
   }
});

// slick init
function initSlickCarousel() {
 ResponsiveHelper.addRange({
   '..767': {
     on: function() {
       jQuery('.spotlight-slider').slick({
         slidesToScroll: 1,
         rows: 0,
         arrows: false
       });
     },
     off: function() {
       jQuery('.spotlight-slider').slick('destroy');
     }
   }
 });
}

// content tabs init
function initTabs() {
 ResponsiveHelper.addRange({
   '768..': {
     on: function() {
       jQuery('.tabset').tabset({
         tabLinks: 'a',
         addToParent: true,
         defaultTab: true
       });
     },
     off: function() {
       jQuery('.tabset').tabset('destroy');
     }
   }
 });
}

export default Spotlight;
