import Backbone, { Router, Model } from 'backbone';
import Header from './views/Header';
import Footer from './views/Footer';

import Main from './views/Main';
import Gallery from './views/Gallery';
import Spotlight from './views/Spotlight';
import NotFound from './views/NotFound';

import './jquery.main.js';
import './vendors/materialize.min.js';

import '../css/vendors/materialize.min.css';
import '../scss/main.scss';

const spotlight = {
  cabelas: {
    title: "Bass Pro Shops and Carbela's",
    logo: `<img src=${require('../images/cabelasBassLogoSpot.png')} />`,
    text: require('./templates/spotlights/cabelas.html'),
    thumbs: require('./templates/spotlights/cabelas-thumbs.html'),
    images: require('./templates/spotlights/cabelas-content.html')
  },
  homedepot: {
    title: 'Home Depot',
    logo: `<img style="width: 15%" src=${require('../images/hDepot.svg')} />`,
    text: require('./templates/spotlights/homedepot.html'),
    thumbs: require('./templates/spotlights/homedepot-thumbs.html'),
    images: require('./templates/spotlights/homedepot-content.html')
  },
  fandango: {
    title: 'Fandango',
    logo: `<img src=${require('../images/logo05.png')} />`,
    text: require('./templates/spotlights/fandango.html'),
    thumbs: require('./templates/spotlights/fandango-thumbs.html'),
    images: require('./templates/spotlights/fandango-content.html')
  },
  grillsgonewild: {
    title: 'Grills Gone Wild',
    logo: `<img style="width: 20%" src=${require('../images/grillgoneLogo3sized.png')} />`,
    text: require('./templates/spotlights/grillsgonewild.html'),
    thumbs: require('./templates/spotlights/grillsgonewild-thumbs.html'),
    images: require('./templates/spotlights/grillsgonewild-content.html')
  },
  ultimateonlineshoppingspree: {
    title: "Ultimate Shopping Spree",
    logo: `<img style="width: 25%" src=${require('../images/ultimateonlineshoppingspree.png')} />`,
    text: require('./templates/spotlights/ultimateonlineshoppingspree.html'),
    thumbs: require('./templates/spotlights/ultimateonlineshoppingspree-thumbs.html'),
    images: require('./templates/spotlights/ultimateonlineshoppingspree-content.html')
  },
  stubhub: {
    title: "Stubhub",
    logo: `<img src=${require('../images/stubhub.png')} />`,
    text: require('./templates/spotlights/stubhub.html'),
    thumbs: require('./templates/spotlights/stubhub-thumbs.html'),
    images: require('./templates/spotlights/stubhub-content.html')
  },
  cornhole: {
    title: "Cornhole",
    logo: `<img src=${require('../images/cornhole1.svg')} />`,
    text: require('./templates/spotlights/cornhole.html'),
    thumbs: require('./templates/spotlights/cornhole-thumbs.html'),
    images: require('./templates/spotlights/cornhole-content.html')
  },
  honda: {
    title: "Honda",
    logo: `<img style="width: 15%" src=${require('../images/honda-blue.png')} />`,
    text: require('./templates/spotlights/honda.html'),
    thumbs: require('./templates/spotlights/honda-thumbs.html'),
    images: require('./templates/spotlights/honda-content.html')
  }
};

class App extends Router {
  get routes() {
    return  {
      '': 'index',
      'gallery': 'gallery',
      'gallery/:params': 'gallery',
      'notFound': 'notFound'
    }
  }

  postInitialize() {
    if (!this.header) {
      this.header = new Header({ spotlight });
    }
    if (!this.footer) {
      this.footer = new Footer();
    }
  }

  index() {
    this.main = new Main();
  }

  gallery(params) {
    if (!params) {
      this.main = new Gallery();
    } else if (spotlight[params]) {
      this.main = new Spotlight(spotlight[params]);
    } else {
      this.notFound();
    }
  }

  notFound() {
    this.main = new NotFound();
  }
}

(function($) {
  // standard on load code goes here with $ prefix
  // note: the $ is setup inside the anonymous function of the ready command

    window.app = {};
    window.app = new App();

    window.app.postInitialize();

  if (!Backbone.history.start({ pushState: true })) {
    window.app.navigate('notFound', { trigger: true });
  }

  window.Backbone = Backbone;
})(window.$);
